import React from 'react'
import Container from './container'
import * as styles from './showcase-image-double.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const ShowcaseImageDouble = ({ imageLeft, imageRight }) => (
  <Container>
    <div className={styles.images}>
        <GatsbyImage className={styles.image} alt="" image={imageLeft.gatsbyImageData} />
        <GatsbyImage className={styles.image} alt="" image={imageRight.gatsbyImageData} />
    </div> 
  </Container>
)

export default ShowcaseImageDouble
