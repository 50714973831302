import React from 'react'
import Container from './container'
import * as styles from './text-block.module.css'

const TextBlock = ({ title, subtitle }) => (
  <Container>
    <div className={styles.textBlock}>
      <h2 className={styles.title}>{title}</h2>
      <h4 className={styles.subtitle}>{subtitle}</h4>
    </div>
  </Container>
)

export default TextBlock
