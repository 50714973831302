import React from 'react'
import Container from './container'
import * as styles from './case-study-header.module.css'

const CaseStudyHeader = ({ title, subtitle, description }) => (
  <Container>
    <div className={styles.caseStudyHeader}>
      <h3 className={styles.subtitle}>{subtitle}</h3>
      <h1 className={styles.title}>{title}</h1>
      <h4 className={styles.description}>{description}</h4>
    </div>
  </Container>
)

export default CaseStudyHeader