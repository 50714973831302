import React from 'react'
import Container from './container'
import * as styles from './text-block-with-image.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const TextBlockWithImage = ({ title, subtitle, image, leftImage }) => (
  <Container>
    {leftImage ?
    <div className={styles.textBlockWithImage}>
        <GatsbyImage className={styles.image} alt="" image={image.gatsbyImageData} />
        <div className={styles.text}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.subtitle}>{subtitle}</p>
        </div>
    </div>
    :
    <div className={styles.textBlockWithImage}>
        <div className={styles.text}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.subtitle}>{subtitle}</p>
        </div>
        <GatsbyImage className={styles.image} alt="" image={image.gatsbyImageData} />
    </div>
    } 
  </Container>
)

export default TextBlockWithImage
