import React from 'react'
import Container from './container'
import Divider from './divider.js'
import * as styles from './step.module.css'

const Step = ({ title, description }) => (
  <Container>
    <div className={styles.step}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </div>
    <Divider/>
  </Container>
)

export default Step