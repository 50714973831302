
import React from 'react'

const Divider = ({ children, as = 'div' }) => {
  const Tag = as

  return (
    <Tag
      style={{
        height: '0.5px',
        width: '100%',
        backgroundColor: 'var(--background-dark)'
      }}
    >
      {children}
    </Tag>
  )
}

export default Divider
