import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import CaseStudyHeader from '../components/case-study-header'
import ShowcaseImage from '../components/showcase-image'
import TextBlockWithImage from '../components/text-block-with-image'
import SpacerRegular from '../components/spacer-regular'
import ShowcaseImageDouble from '../components/showcase-image-double'
import TextBlock from '../components/text-block'
import Step from '../components/step'
import SpacerLarge from '../components/spacer-large'
import SpacerMedium from '../components/spacer-medium'

class CaseStudyFast extends React.Component {
  render() {
    const [header] = get(this, 'props.data.header.nodes')
    const [showcaseImage] = get(this, 'props.data.showcaseImage.nodes')
    const [problem] = get(this, 'props.data.problem.nodes')
    const [showcaseDoubleOne] = get(this, 'props.data.showcaseDoubleOne.nodes')
    const [solution] = get(this, 'props.data.solution.nodes')
    const [processStepOne] = get(this, 'props.data.processStepOne.nodes')
    const [processStepTwo] = get(this, 'props.data.processStepTwo.nodes')
    const [processStepThree] = get(this, 'props.data.processStepThree.nodes')
    const [processStepFour] = get(this, 'props.data.processStepFour.nodes')
    const [showcaseDoubleTwo] = get(this, 'props.data.showcaseDoubleTwo.nodes')
    const [fixesTitle] = get(this, 'props.data.fixesTitle.nodes')
    const [fixOneProblem] = get(this, 'props.data.fixOneProblem.nodes')
    const [fixOneSolution] = get(this, 'props.data.fixOneSolution.nodes')
    const [fixTwoProblem] = get(this, 'props.data.fixTwoProblem.nodes')
    const [fixTwoSolution] = get(this, 'props.data.fixTwoSolution.nodes')
    const [fixTwoShowcaseImage] = get(this, 'props.data.fixTwoShowcaseImage.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Case Study: Well Pharmacy" />
        <CaseStudyHeader
          title={header.title}
          subtitle={header.subtitle}
          description={header.description.description}
        />
        <ShowcaseImage image={showcaseImage.image}/>
        <SpacerMedium/>
        <TextBlock
          title={problem.title}
          subtitle={problem.subtitle.subtitle}
        />
        <SpacerRegular/>
        <ShowcaseImageDouble
          imageLeft={showcaseDoubleOne.imageLeft}
          imageRight={showcaseDoubleOne.imageRight}
        />
        <SpacerRegular/>
        <TextBlock
          title={solution.title}
          subtitle={solution.subtitle.subtitle}
        />
        <SpacerRegular/>
        <Step
          title={processStepOne.title}
          description={processStepOne.description.description}
        />
        <Step
          title={processStepTwo.title}
          description={processStepTwo.description.description}
        />
        <Step
          title={processStepThree.title}
          description={processStepThree.description.description}
        />
        <Step
          title={processStepFour.title}
          description={processStepFour.description.description}
        />
        <SpacerRegular/>
        <ShowcaseImageDouble
          imageLeft={showcaseDoubleTwo.imageLeft}
          imageRight={showcaseDoubleTwo.imageRight}
        />
        <SpacerMedium/>
        <TextBlock
          title={fixesTitle.title}
          subtitle={fixesTitle.subtitle.subtitle}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={fixOneProblem.title}
          subtitle={fixOneProblem.subtitle.subtitle}
          image={fixOneProblem.image}
          leftImage={fixOneProblem.leftImage}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={fixOneSolution.title}
          subtitle={fixOneSolution.subtitle.subtitle}
          image={fixOneSolution.image}
          leftImage={fixOneSolution.leftImage}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={fixTwoProblem.title}
          subtitle={fixTwoProblem.subtitle.subtitle}
          image={fixTwoProblem.image}
          leftImage={fixTwoProblem.leftImage}
        />
        <SpacerRegular/>
        <ShowcaseImage image={fixTwoShowcaseImage.image}/>
        <SpacerRegular/>
        <TextBlockWithImage
          title={fixTwoSolution.title}
          subtitle={fixTwoSolution.subtitle.subtitle}
          image={fixTwoSolution.image}
          leftImage={fixTwoSolution.leftImage}
        />
        <SpacerLarge/>
      </Layout>
    )
  }
}

export default CaseStudyFast

export const pageQuery = graphql`
  query CaseStudyWellPharmacyQuery {
    header: allContentfulHeader(
      filter: { contentful_id: { eq: "7JKuGQwgCl2pjFB1vj8VG5" } }
    ) {
      nodes {
        title
        subtitle
        description {
          description
        }
      }
    }
    showcaseImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "U1HxCS5IfXKfS6MQgt2R3" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    showcaseDoubleOne: allContentfulShowcaseImageDouble(
      filter: { contentful_id: { eq: "6bwuVi0h81QGFmXhuogntp" } }
    ) {
      nodes {
        imageLeft {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
        imageRight {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    showcaseDoubleTwo: allContentfulShowcaseImageDouble(
      filter: { contentful_id: { eq: "7uu9IFPI8Ye51Pi0zQxYhP" } }
    ) {
      nodes {
        imageLeft {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
        imageRight {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    problem: allContentfulTextBlock(
      filter: { contentful_id: { eq: "6nKSJFqmpuwIf55jj4LgE9" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    solution: allContentfulTextBlock(
      filter: { contentful_id: { eq: "3wLmK6aAQXkPHIdy08cnge" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    fixesTitle: allContentfulTextBlock(
      filter: { contentful_id: { eq: "5XzY1B320VKZ9zQBwDZfKD" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    fixOneProblem: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "3Y6IIkiFUXXLPkXI3I8tm3" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    fixOneSolution: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "5H4JTnAmhT6XOe9GICYJuU" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    fixTwoProblem: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "1nkI8MQlnezXRY2ce8U1x7" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    fixTwoSolution: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "11rVUwjl4Pb56rF9YmjtkO" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    fixTwoShowcaseImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "58wEOWdNxO5kKf9q2Q7d4F" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    processStepOne: allContentfulStep(
      filter: { contentful_id: { eq: "1StaGrnfUSnWamXo1NNGag" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
    processStepTwo: allContentfulStep(
      filter: { contentful_id: { eq: "DPq5xc3ldGn6hah6O1BtQ" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
    processStepThree: allContentfulStep(
      filter: { contentful_id: { eq: "WNDli6Q6mJOuEvEnwDR9l" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
    processStepFour: allContentfulStep(
      filter: { contentful_id: { eq: "xOf24SY6FD16L4satHjws" } }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
  }
`
