import React from 'react'
import * as styles from './showcase-image.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const ShowcaseImage = ({ image }) => (
  <div className={styles.showcaseImage}>
      <GatsbyImage className={styles.image} alt="" image={image.gatsbyImageData} />
  </div>
)

export default ShowcaseImage