import React from 'react'

const SpacerLarge = ({ children, as = 'div' }) => {
  const Tag = as

  return (
    <Tag
      style={{
        height: 'var(--spacer-l)',
      }}
    >
      {children}
    </Tag>
  )
}

export default SpacerLarge
